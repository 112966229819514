<template>
  <div class="select_wrap">
    <select v-model="value">
      <option v-for="val in infoList" :value="val[0]"><!-- ★ valueにkeyというオブジェクトを設定 -->
        {{ format_val(val[1]) }}
      </option>
    </select>
  </div>

</template>
<script>
import CommonModule from "../../module/common";

export default {
  init_prop_info_list:{},
  props: {
    prop_key: {type: String, default: ""},
    prop_info_list: {type: Array, default: []},
    prop_default: {type: String, default: ""},

  },
  data() {
    return {
      //初期値
      value: "",
      //選択肢
      infoList: this.prop_info_list
    }
  },
  watch: {
    value: function() {
      this.$emit("input", this.value);
    },

    //親コンポーネントの値が変更されたら、プルダウンを再生成する
    '$parent.setting_value_sf_code1': {
      handler(val){
        this.change_pull_down(this.$parent.setting_value_sf_code1_key);
      }
    },
    '$parent.setting_value_sf_code2': {
      handler(val){
        this.change_pull_down(this.$parent.setting_value_sf_code2_key);
      }
    },
    '$parent.setting_value_sf_code3': {
      handler(val){
        this.change_pull_down(this.$parent.setting_value_sf_code3_key);
      }
    }
  },
  methods:{
    change_pull_down(target_key){
      let product_codes = {...this.$parent.product_codes};
      const prop_key = this.prop_key;
      let init_prop_info_list = [...this.init_prop_info_list];
      let init_prop_info_list_len = Object.keys(init_prop_info_list).length;
      const parent_v = this.$parent;
      let appear_vals = [];

      //product_codesが無い
      if( !Object.keys(product_codes) ){
        return false;
      }


      Object.keys(product_codes).forEach(key => {
        //対象キーが含まれているかフィルタする
        if (
            !check_filter(product_codes[key], parent_v.setting_value_sf_code1, parent_v.setting_value_sf_code1_key) ||
            !check_filter(product_codes[key], parent_v.setting_value_sf_code2, parent_v.setting_value_sf_code2_key) ||
            !check_filter(product_codes[key], parent_v.setting_value_sf_code3, parent_v.setting_value_sf_code3_key)

        ) {
          return;
        }

        appear_vals.push(product_codes[key][prop_key]);
      });
      //プルダウンに渡されていた初期値からフィルタリング結果に差し替え
      init_prop_info_list.forEach((element, key) => {
        if( key != "" && !appear_vals.includes(init_prop_info_list[key][0]) ){
          // console.log("↓削除");
          // console.log(init_prop_info_list[key]);
          //delete init_prop_info_list[key];
        }
      });
      init_prop_info_list = init_prop_info_list.filter(Boolean);
      // console.log(init_prop_info_list_len);
      // console.log(Object.keys(init_prop_info_list).length);


      //1件でも減っていたら文字列変更（かつ自分自身ではない）
      if( this.value ) {
        if( init_prop_info_list_len > init_prop_info_list.length){
          //もともとあった空白を削除
          init_prop_info_list = init_prop_info_list.filter((element)=>{
              return element[0] !== undefined && element[0] !== 0 && element[0] !== null && element[0] !== "";
          });

          init_prop_info_list.unshift(["","選択しなおす"] );
        }
      }
      this.$set(this,"infoList",init_prop_info_list);
    },
    format_val(_val){
      if( _val && !isNaN(_val) && ["damage_payment__c"].includes(this.$parent.setting_value_sf_code1_key) ){
        //言語ごとの表示

        if(language && language == 'en'){
          //英語
          const fmt =  new Intl.NumberFormat("en-US",{
            notation: "compact",
            compactDisplay: "long"
          })

          return fmt.format(_val )+" yen";

        }else{
          //日本語
          const fmt =  new Intl.NumberFormat("ja-JP",{
            notation: "compact",
          })

          return fmt.format(_val )+"円";
        }

      }
      else if(_val && !isNaN(_val)){
        return parseFloat(_val).toLocaleString();
      }
      return _val;
    }

  },
  created() {
    this.init_prop_info_list = this.prop_info_list;

    //ソートを行う
    if(this.init_prop_info_list){
      //キーを含んだ配列に変換 オブジェクト⇒配列
      //値段順
      this.init_prop_info_list.sort(CommonModule.sort_arr_num_only);

      this.$set(this,"infoList",this.init_prop_info_list);//プルダウンを更新（初回のみ）
    }

    if( this.prop_default ){
      this.value = this.prop_default;
      this.$set(this,"value",this.value);
    }
  }
}


//フィルタチェック
function check_filter(product_code, setting_value_sf_code_val, setting_value_sf_code_key) {
  let val = product_code[setting_value_sf_code_key];
  if(
      setting_value_sf_code_val && setting_value_sf_code_val && val != setting_value_sf_code_val
  ){
    return false;
  }

  return true;
}
</script>
