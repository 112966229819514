import "core-js/stable";
import "regenerator-runtime/runtime";

import form_select from '../components/form/simulation_select'
import simulation_start_date from '../components/form/simulation_start_date'
import simulation_end_date from '../components/form/simulation_end_date'
import CommonModule from "../module/common";

//ダイアログの表示
// $('.form_dialog').on('click', function () {
//   return false;
// });
window.v_simulation = new Vue({
  el: '#hoken_list',

  //コンポーネントの指定
  components: {
    'form_select': form_select,
    'simulation_start_date': simulation_start_date,
    'simulation_end_date': simulation_end_date
  },
  data() {
    return Object.assign({
      setting_value_sf_code1: '',
      setting_value_sf_code1_key: '',
      setting_value_sf_code2: '',
      setting_value_sf_code2_key: '',
      setting_value_sf_code3: '',
      setting_value_sf_code3_key: '',
      sf_keys: {},//erb側で設定した、サブコードの指定キー
      product_codes: product_codes,//erb側で値を初期化しているものを渡す
      items: [],
      check_item_code: "",
      submit_ok: false
    }, bind_data)
  },
  mounted() {
    this.create_list();

    //console.log(product_codes);
  },
  watch: {
    check_item_code: function () {
      index_simulator.simulate();
      this.check_submit();
    },

    start_select_year: function () {
      this.create_list();
    },
    start_select_month: function () {
      this.create_list();
    },
    start_select_day: function () {
      this.create_list();
    },
    end_select_year: function () {
      this.create_list();
    },
    end_select_month: function () {
      this.create_list();
    },
    end_select_day: function () {
      this.create_list();
    },
    setting_value_sf_code1: function () {
      this.create_list();
    },
    setting_value_sf_code2: function () {
      this.create_list();
    },
    setting_value_sf_code3: function () {
      this.create_list();
    }
  },
  methods: {
    checkSubmit() {
      try {
        let simulation_start_date = this.$refs.simulation_start_date;
        if (!simulation_start_date.validate()) {
          CommonModule.move_first_field_with_errors();
          return false;
        }

        if(end_date_use){
          let simulation_end_date = this.$refs.simulation_end_date;
          if (!simulation_end_date.validate()) {
            CommonModule.move_first_field_with_errors();
            return false;
          }
        }
      } catch (e) {
        console.log(e.message);
        return false;
      }
      return true;//正常完了！

    },
    create_list() {
      let _items = [];
      let v = this;
      let selected_item_code_check = false;
      Object.keys(product_codes).forEach(key => {
        //console.log(this.setting_value_sf_code1);
        //対象キーが含まれているかフィルタする
        if (
          !check_filter(product_codes[key], v.setting_value_sf_code1, v.setting_value_sf_code1_key) ||
          !check_filter(product_codes[key], v.setting_value_sf_code2, v.setting_value_sf_code2_key) ||
          !check_filter(product_codes[key], v.setting_value_sf_code3, v.setting_value_sf_code3_key)
        ) {
          return;
        }

        //終期日選択がある
        if (end_date_use && !check_filter_end_date(product_codes[key])) {
          return;
        }
        if (v.check_item_code === key) {
          selected_item_code_check = true;
        }

        //ペアの賠責なしは表示しない
        if( product_codes[key]["liability"] == 1 ){
          return;
        }
        _items.push(product_codes[key])
      });

      //選択していたアイテムコードがなくなったら
      if (!selected_item_code_check) {
        this.check_item_code = "";
      }

      //選択が1件になっていたら、自動的に選択させる
      if (_items.length === 1 && this.check_item_code != _items[0]["item_code"] ) {
        this.check_item_code = _items[0]["item_code"];
        if( $('input[name="form[item_code_sub]"][value="' + _items[0]["item_code"] + '"]').length > 0 ){
          $('input[name="form[item_code_sub]"][value="' + _items[0]["item_code"] + '"]').prop('checked',true);
          setTimeout(function(){
            $('input[name="form[item_code_sub]"][value="' + _items[0]["item_code"] + '"]').prop('checked',true);
          },100);
          index_simulator.simulate();
        }

      }
      this.items = _items;
      this.check_submit();
    },
    check_submit() {
      let simulation_start_date = this.$refs.simulation_start_date;
      let simulation_end_date = this.$refs.simulation_end_date;

      let end_date_error = false;
      const simulator_end_date_year = $('#form_end_date_1i').val();
      const simulator_end_date_month = $('#form_end_date_2i').val();
      const simulator_end_date_day = $('#form_end_date_3i').val();
      if (!isDate(simulator_end_date_year + '/' + simulator_end_date_month + '/' + simulator_end_date_day)) {
        end_date_error = true;
      }

      if (
        this.check_item_code &&
        simulation_start_date.validate() &&
        ( !end_date_use || simulation_end_date.validate() ) &&
        !end_date_error
      ) {
        this.submit_ok = true;
      } else {
        this.submit_ok = false;
      }
    }
  }
});

//フィルタチェック
function check_filter(product_code, setting_value_sf_code_val, setting_value_sf_code_key) {
  const val = product_code[setting_value_sf_code_key];
  if (
    setting_value_sf_code_val && val != setting_value_sf_code_val
  ) {
    return false;
  }

  return true;
}

//フィルタチェック 終期日バージョン 211011
function check_filter_end_date(product_code) {


  const month = parseInt(product_code["months"]);
  const days_max = parseInt(product_code["days_max"]);
  const days_min = parseInt(product_code["days_min"]);

  const start_select_year = $('#form_start_date_1i').val();
  const start_select_month = $('#form_start_date_2i').val();
  const start_select_day = $('#form_start_date_3i').val();

  if( !(start_select_day && start_select_month && start_select_day)){
    return false;
  }

  const end_select_year = $('#form_end_date_1i').val();
  const end_select_month = $('#form_end_date_2i').val();
  const end_select_day = $('#form_end_date_3i').val();

  if( !(end_select_year && end_select_month && end_select_day)){
    return false;
  }

  const ret = CommonModule.get_diff_month_day(
    parseInt(end_select_year), parseInt(end_select_month), parseInt(end_select_day),
    parseInt(start_select_year), parseInt(start_select_month), parseInt(start_select_day)
  );
  if (ret === false) {
    return false;
  }

  let diffmonth2 = ret[0];
  let diffday = ret[1]+1;

  if (diffmonth2 === 0) {//1ヶ月未満
    if (!(days_min <= diffday && days_max >= diffday)) {
      return false;
    }else if(month){
      return false;
    }
  } else if (diffmonth2 > 0) {//1ヶ月以上
    //1ヶ月以上の場合は、契約応当日で算出
    if (!(month === diffmonth2)) {
      return false;
    }else if(days_min && days_max){
      return false;
    }
  }


  return true;
}
