//日付プルダウン設定
function formSetDay(date_year, date_month) {
  const lastday = formSetLastDay(date_year, date_month);
  let options = {};
  for (let i = 1; i <= lastday; i++) {
    options[i] = ("0" + i).slice(-2);
  }
  return options;
}

function formSetLastDay(year, month) {
  const lastday = ['', 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
    lastday[2] = 29;
  }
  return lastday[month];
}

function range(start, end) {
  const list = [];
  for (let i = start; i <= end; i++) {
    list.push(i);
  }
  return list;
}

//最初のフォームエラーに飛ぶ (jQueryを利用）
function move_first_field_with_errors() {
  setTimeout(function (){
    window.scrollTo({
      top: $('.field_with_errors').eq(0).offset().top - parseInt($('body').css('padding-top')),
      behavior: "smooth"
    });

  },200);
  //少し遅延させて　DOMの配置を待つ

}

//指定の日付の方が対象より大きいか
function check_date_gt(gt_date_day, lt_date) {

  const date_now = new Date();

  return date_now.setDate(date_now.getDate() + gt_date_day) >= lt_date;
}

//指定の日付の方が対象より小さいか
function check_date_lt(lt_date_day,gt_date){
  const date_now = new Date();
  return date_now.setDate(date_now.getDate() + lt_date_day) < gt_date;
}

function sort_arr_num_only(a, b) {

  const sa = String(a[0]).replace(/(\d+)/g, m => m.padStart(30, '0'));
  const sb = String(b[0]).replace(/(\d+)/g, m => m.padStart(30, '0'));

  if( sa == "" ){
    return -1;
  }else if( sb == "" ){
    return 1;
  }

  if((a[0] && isNaN(a[0])) || (b[0] && isNaN(b[0]))){
    return 0;
  }

  return sa < sb ? -1 : sa > sb ? 1 : 0;
}

function get_diff_month_day(simulator_end_date_year, simulator_end_date_month, simulator_end_date_day, simulator_start_date_year, simulator_start_date_month, simulator_start_date_day) {

  //単純な月のまたぎだけみたいため、　始期日、終期日ともに1日としてカウント
  let start_date = new Date(simulator_start_date_year, simulator_start_date_month - 1, 1);
  let end_date = new Date(simulator_end_date_year, simulator_end_date_month - 1, 1);
  //始期日と周期日の差を調べる
  let diffmonth = monthDiff(
    start_date, // 始期日
    end_date  // 終期日
  );

  start_date = new Date(simulator_start_date_year, simulator_start_date_month - 1, simulator_start_date_day);
  end_date = new Date(simulator_end_date_year, simulator_end_date_month - 1, simulator_end_date_day);

  const diffday = ((end_date - start_date) / 86400000);


  //月末の最終日
  const end_getumatu_day = (new Date(simulator_end_date_year, simulator_end_date_month, 0)).getDate();

  //契約応当日ベースで1ヶ月をすぎている場合
  if (diffmonth > 0 && simulator_start_date_day <= simulator_end_date_day) {
    diffmonth += 1;
  } else if (diffmonth === 1 && (simulator_start_date_day - 1) > simulator_end_date_day && simulator_end_date_day < end_getumatu_day) {
    diffmonth -= 1;
  }
  //31日以上で月が0だったら（月内で31日だったら、1ヶ月にする）
  else if (diffmonth === 0 && diffday >= 31) {
    diffmonth += 1
  }
  //月が0だが、月初め〜月末である
  else if (diffmonth === 0 && simulator_start_date_day === 1 && simulator_end_date_day >= end_getumatu_day) {
    diffmonth += 1
  }

  return [diffmonth, diffday]
}

function monthDiff(d1, d2) {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}
function ml(arrstr){
  if(language !== "ja"){
    return arrstr[1];
  }else{
    return arrstr[0];
  }
}


// 関数をエクスポートします。
export default {
  get_diff_month_day,
  formSetDay,
  formSetLastDay,
  range,
  move_first_field_with_errors,
  check_date_gt,
  check_date_lt,
  sort_arr_num_only,
  ml
};
